<template>
  <div style="width:90%;height:auto; margin:0 auto;">
    <el-form ref="ruleForm" :model="form" label-width="140px" label-position="left">
    
      <el-descriptions class="margin-top"  title="人数（人）" :column="3" :size="size" border>
      <el-descriptions-item >
      <template slot="label">
           <i class="el-icon-user"></i>总数</template>
        {{schooldemo.personnelTotal}}
        人
      </el-descriptions-item>
      <el-descriptions-item >
      <template slot="label">
           <i class="el-icon-user"></i>专职</template>
        {{schooldemo.fullTime}}
        人
      </el-descriptions-item>
      <el-descriptions-item >
      <template slot="label">
           <i class="el-icon-user"></i>兼职</template>
        {{schooldemo.partTime}}
        人
      </el-descriptions-item>
      </el-descriptions>  

      <el-descriptions class="margin-top"  title="专职图书管理员职称结构（人）" :column="3" :size="size" border>
      </el-descriptions>
      <el-descriptions class="margin-top"  title="高级" :column="3" :size="size" border>
        <el-descriptions-item >
      <template slot="label">
           <i class="el-icon-user"></i>图书馆系列</template>
        {{schooldemo.seniorLibrary}}
        人
      </el-descriptions-item>
      <el-descriptions-item >
      <template slot="label">
           <i class="el-icon-user"></i>其他系列</template>
        {{schooldemo.seniorOther}}
        人
      </el-descriptions-item>
      </el-descriptions>
      
      <el-descriptions class="margin-top"  title="中级" :column="3" :size="size" border>
       <el-descriptions-item >
      <template slot="label">
           <i class="el-icon-user"></i>图书馆系列</template>
        {{schooldemo.intermediateLibrary}}
        人
      </el-descriptions-item>
      <el-descriptions-item >
      <template slot="label">
           <i class="el-icon-user"></i>其他系列</template>
        {{schooldemo.intermediateOther}}
        人
      </el-descriptions-item>
      </el-descriptions>

          <el-descriptions class="margin-top"  title="初级" :column="3" :size="size" border>
       <el-descriptions-item >
      <template slot="label">
           <i class="el-icon-user"></i>图书馆系列</template>
        {{schooldemo.primaryLibrary}}
        人
      </el-descriptions-item>
     <el-descriptions-item >
      <template slot="label">
           <i class="el-icon-user"></i>其他系列</template>
        {{schooldemo.primaryOther}}
        人
      </el-descriptions-item>
      </el-descriptions> 
      
       <el-descriptions class="margin-top"  title="无职称" :column="1" :size="size" border>
        <el-descriptions-item >
      <template slot="label">
           <i class="el-icon-user"></i>无职称</template>
        {{schooldemo.noTitle}}
        人
      </el-descriptions-item>
       </el-descriptions>
        <el-descriptions class="margin-top"  title="图书馆专业毕业人数" :column="1" :size="size" border>
        <el-descriptions-item >
      <template slot="label">
           <i class="el-icon-user"></i>图书馆专业毕业人数</template>
        {{schooldemo.graduatedLibraryNumber}}
        人
      </el-descriptions-item>
       </el-descriptions>
       <el-descriptions class="margin-top"  title="年度培训" :column="1" :size="size" border>
        <el-descriptions-item >
      <template slot="label">
           <i class="el-icon-user"></i>年度培训</template>
        {{schooldemo.trainNumber}}
        人次
      </el-descriptions-item>
      </el-descriptions>
  
    </el-form>
     <div style="text-align:center; padding-top:10px">
     
    </div>
  </div>
</template>

<script>

import { Message, MessageBox } from 'element-ui'
import { getDictListByCode } from '@/api/dict'
import { getDistrictTableInfo } from '@/api/taskSchool'

export default {
  name: '',
  inject: ['getListByAppId'],
  props: { // 第二种方式
    proid: {
      type: String,
      required: true
    },
    deptId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      tbsm:"填报说明</br>本显示学校教学设备的配备的基本情况，包含小学科学、中学物理、中学化学、中学生物实验室和其附属用房内和思政、史地、音乐、美术、书法、体育、心理健康、信息科技、劳技/通用/综合实践等，以及表中类未体现的功能教室及其附属用房内的设备；网络及外部设备；图书馆/阅览室设备。</br>1.实验室设备和功能教室设备：理科实验室和功能教室及附属用房内的桌、凳、台、柜、厨、通风换气、空调等，以购入的金额为准。</br>2.网络及外部设备：是指不包括计算机在内的投入校园网网络建设的金额之和，以购入时的金额为准；网络及外部设备包括：机柜、服务器、交换机、UPS电源、控制器、路由器、调制解调器（猫）等</br>3.图书馆、阅览室设备：是指学校图书馆、阅览室内所有家具类、办公类设备、流通类、信息化软硬件等，但不包含多媒体及计算机。</br>4.合计：表中前面项目的总和。",
      up1Visible: false,
      up2Visible: false,
      form: {
        userName:'',
        userCompany:''
      },
      xmName: '',
      themeName: '',
      schemaNa: '',
      standard:{
        kxys:1,
        kxstfz:2,
        kxsyfz:3,
        wlys:9,
        wlfz:91,
        hxys:12,
        hxfz:13,
        swys:27,
        swfz:28,
        swtj:29
      },
      multipleSelection: [],
      schooldemo:{js:"10"},
      txData: [],
      types: [],
      options: [{
          value: '1',
          label: '小学'
        }, {
          value: '2',
          label: '初中'
        }, {
          value: '3',
          label: '完中'
        },{
          value: '4',
          label: '高中'
        }, {
          value: '5',
          label: '九年一贯'
        },{
          value: '6',
          label: '十二年一贯'
        }]
    }
  },
  created() {
     this.getInfo()
  },
  methods: {
     getInfo(){
      var listQuery={}
      listQuery.type="7"
      listQuery.id=this.proid
      listQuery.departmentId=this.deptId
      getDistrictTableInfo(listQuery).then(response => {
        this.schooldemo=response.data
        
      })
    },
     closePage() {
    
          this.$emit('update:visible', false)
          this.getListByAppId()
      
    }
  }
}
</script>
