<template>
  <div style="width:90%;height:auto; margin:0 auto;">
    <el-form ref="ruleForm" :model="form" label-width="140px" label-position="left">
     <el-descriptions class="margin-top"  title="小学科学" :column="2" :size="size" border v-if="this.primary">
     <el-descriptions-item >
      <template slot="label">
        <i class="el-icon-user"></i>间数 </template>
        {{schooldemo.primarySchoolScienceNumber}}
        间
      </el-descriptions-item>
       <el-descriptions-item>
      <template slot="label">
        <i class="el-icon-user"></i>面积 </template>
        {{schooldemo.primarySchoolScienceArea}}
        ㎡
      </el-descriptions-item>
     </el-descriptions>
    <el-descriptions class="margin-top"  title="小学书法" :column="2" :size="size" border v-if="this.primary">
     <el-descriptions-item >
      <template slot="label">
        <i class="el-icon-user"></i>间数 </template>
        {{schooldemo.primarySchoolCalligraphyNumber}}
        间
      </el-descriptions-item>
       <el-descriptions-item>
      <template slot="label">
        <i class="el-icon-user"></i>面积 </template>
        {{schooldemo.primarySchoolCalligraphyArea}}
        ㎡
      </el-descriptions-item>
    </el-descriptions>
      <el-descriptions class="margin-top"  title="中学物理" :column="2" :size="size" border v-if="this.junior||this.senior">
     <el-descriptions-item >
      <template slot="label">
           <i class="el-icon-user"></i>间数</template>
        {{schooldemo.middleSchoolPhysicsNumber}}
        间
      </el-descriptions-item>
       <el-descriptions-item>
      <template slot="label">
        <i class="el-icon-user"></i>面积 </template>
        {{schooldemo.middleSchoolPhysicsArea}}
        ㎡
      </el-descriptions-item>
     </el-descriptions>
  
    <el-descriptions class="margin-top"  title="中学化学" :column="2" :size="size" border v-if="this.junior||this.senior">
     <el-descriptions-item >
      <template slot="label">
           <i class="el-icon-user"></i>间数</template>
        {{schooldemo.middleSchoolChemistryNumber}}
        间
      </el-descriptions-item>
       
       <el-descriptions-item>
      <template slot="label">
        <i class="el-icon-user"></i>面积 </template>
        {{schooldemo.middleSchoolChemistryArea}}
        ㎡
      </el-descriptions-item>
     </el-descriptions>

     <el-descriptions class="margin-top"  title="中学生物" :column="2" :size="size" border v-if="this.junior||this.senior">
     <el-descriptions-item >
      <template slot="label">
           <i class="el-icon-user"></i>间数</template>
        {{schooldemo.middleSchoolBiologyNumber}}
        间
      </el-descriptions-item>
       <el-descriptions-item>
      <template slot="label">
        <i class="el-icon-user"></i>面积 </template>
        {{schooldemo.middleSchoolBiologyArea}}
        ㎡
      </el-descriptions-item>
     </el-descriptions>

     <el-descriptions class="margin-top"  title="中学史地" :column="2" :size="size" border v-if="this.junior||this.senior">
     <el-descriptions-item >
      <template slot="label">
           <i class="el-icon-user"></i>间数</template>
        {{schooldemo.middleSchoolHistoricalGeographyNumber}}
        间
      </el-descriptions-item>
       <el-descriptions-item>
      <template slot="label">
        <i class="el-icon-user"></i>面积 </template>
        {{schooldemo.middleSchoolHistoricalGeographyArea}}
        ㎡
      </el-descriptions-item>
     </el-descriptions>

     <el-descriptions class="margin-top"  title="中小学思政" :column="2" :size="size" border>
     <el-descriptions-item >
      <template slot="label">
           <i class="el-icon-user"></i>间数</template>
        {{schooldemo.middleSchoolPoliticalNumber}}
        间
      </el-descriptions-item>
       <el-descriptions-item>
      <template slot="label">
        <i class="el-icon-user"></i>面积 </template>
        {{schooldemo.middleSchoolPoliticalArea}}
        ㎡
      </el-descriptions-item>
     </el-descriptions>

      <el-descriptions class="margin-top"  title="中小学音乐" :column="2" :size="size" border>
     <el-descriptions-item >
      <template slot="label">
           <i class="el-icon-user"></i>间数</template>
        {{schooldemo.middleSchoolMusicNumber}}
        间
      </el-descriptions-item>
       <el-descriptions-item>
      <template slot="label">
        <i class="el-icon-user"></i>面积 </template>
        {{schooldemo.middleSchoolMusicArea}}
        ㎡
      </el-descriptions-item>
     </el-descriptions>

     <el-descriptions class="margin-top"  title="中小学美术" :column="2" :size="size" border>
     <el-descriptions-item >
      <template slot="label">
           <i class="el-icon-user"></i>间数</template>
        {{schooldemo.middleSchoolArtsNumber}}
        间
      </el-descriptions-item>
       <el-descriptions-item>
      <template slot="label">
        <i class="el-icon-user"></i>面积 </template>
        {{schooldemo.middleSchoolArtsArea}}
        ㎡
      </el-descriptions-item>
     </el-descriptions>

      <el-descriptions class="margin-top"  title="中小学体育活动" :column="2" :size="size" border>
     <el-descriptions-item >
      <template slot="label">
           <i class="el-icon-user"></i>间数</template>
        {{schooldemo.middleSchoolSportsNumber}}
        间
      </el-descriptions-item>
       <el-descriptions-item>
      <template slot="label">
        <i class="el-icon-user"></i>面积 </template>
        {{schooldemo.middleSchoolSportsArea}}
        ㎡
      </el-descriptions-item>
     </el-descriptions>

      <el-descriptions class="margin-top"  title="中小学心理健康" :column="2" :size="size" border>
     <el-descriptions-item >
      <template slot="label">
           <i class="el-icon-user"></i>间数</template>
        {{schooldemo.middleSchoolMentalNumber}}
        间
      </el-descriptions-item>
       <el-descriptions-item>
      <template slot="label">
        <i class="el-icon-user"></i>面积 </template>
        {{schooldemo.middleSchoolMentalArea}}
        ㎡
      </el-descriptions-item>
     </el-descriptions>

     <el-descriptions class="margin-top"  title="中小学信息科技" :column="2" :size="size" border>
     <el-descriptions-item >
      <template slot="label">
           <i class="el-icon-user"></i>间数</template>
        {{schooldemo.middleSchoolInformationNumber}}
        间
      </el-descriptions-item>
       <el-descriptions-item>
      <template slot="label">
        <i class="el-icon-user"></i>面积 </template>
        {{schooldemo.middleSchoolInformationArea}}
        ㎡
      </el-descriptions-item>
     </el-descriptions>

     <el-descriptions class="margin-top"  title="中小学专用录播" :column="2" :size="size" border>
     <el-descriptions-item >
      <template slot="label">
           <i class="el-icon-user"></i>间数</template>
        {{schooldemo.middleSchoolBroadcastNumber}}
        间
      </el-descriptions-item>
       <el-descriptions-item>
      <template slot="label">
        <i class="el-icon-user"></i>面积 </template>
        {{schooldemo.middleSchoolBroadcastArea}}
        ㎡
      </el-descriptions-item>
     </el-descriptions>

     <el-descriptions class="margin-top"  title="中小学劳技/通用技术/综合实践" :column="2" :size="size" border>
     <el-descriptions-item >
      <template slot="label">
           <i class="el-icon-user"></i>间数</template>
        {{schooldemo.middleSchoolTechnologyNumber}}
        间
      </el-descriptions-item>
       <el-descriptions-item>
      <template slot="label">
        <i class="el-icon-user"></i>面积 </template>
        {{schooldemo.middleSchoolTechnologyArea}}
        ㎡
      </el-descriptions-item>
     </el-descriptions>

     <el-descriptions class="margin-top"  title="中小学其他" :column="2" :size="size" border>
     <el-descriptions-item >
      <template slot="label">
           <i class="el-icon-user"></i>间数</template>
        {{schooldemo.middleSchoolOtherNumber}}
        间
      </el-descriptions-item>
       <el-descriptions-item>
      <template slot="label">
        <i class="el-icon-user"></i>面积 </template>
        {{schooldemo.middleSchoolOtherArea}}
        ㎡
      </el-descriptions-item>
     </el-descriptions>
    </el-form>
  
  </div>
</template>

<script>

import { Message, MessageBox } from 'element-ui'
import { getDictListByCode } from '@/api/dict'
import { getDistrictTableInfo } from '@/api/taskSchool'


export default {
  name: '',
  inject: ['getListByAppId'],
  props: { // 第二种方式
    proid: {
      type: String,
      required: true
    },
    deptId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      tbsm:"填报说明</br>本表显示统计年份内中小学的基本情况，包含统一社会信用代码、学校全称、学校类别、班级数、学生数、教师数等,表中数据来源于当年教育事业统计。<br/>1.统一社会信用代码：由登记管理部门发放的统一社会信用代码。<br/>2.学校全称：与学校对外使用公章相对应。<br/>3.学校类别：根据实际情况，在小学、初中、完中、高中、九年一贯、十二年一贯中只能选择一个类别。<br/>4.班级数：应与当年教育事业统计“班级数”保持一致。<br/>5.学生数：应与当年教育事业统计“学生数”数保持一致。<br/>6.教师数：应与当年教育事业统计“教师数”数保持一致。<br/>",
      up1Visible: false,
      up2Visible: false,
      form: {
        userName:'',
        userCompany:''
      },
      schooldemo:{js:"10"},
      xmName: '',
      themeName: '',
      schemaNa: '',
       primary:false,
      junior:false,
      senior:false,
      multipleSelection: [],
      txData: [],
      types: [],
      options: [{
          value: '1',
          label: '小学'
        }, {
          value: '2',
          label: '初中'
        }, {
          value: '3',
          label: '完中'
        },{
          value: '4',
          label: '高中'
        }, {
          value: '5',
          label: '九年一贯'
        },{
          value: '6',
          label: '十二年一贯'
        }]
    }
  },
  created() {
    this.getInfo()
  },
  methods: {
    getInfo(){
      var listQuery={}
      listQuery.type="3"
      listQuery.id=this.proid
      listQuery.departmentId=this.deptId
      
      getDistrictTableInfo(listQuery).then(response => {
        this.schooldemo=response.data.classroomUsage
        var str=response.data.department
        var eduStage=str.split(',')
        for (var i in eduStage) {
        if (eduStage[i] === "0x1") {
          this.primary=true
        }
        if (eduStage[i] === "0x2") {
          this.junior=true
        }
        if (eduStage[i] === "0x4") {
          this.senior=true
        }
      }
      })
    },
     closePage() {
    
          this.$emit('update:visible', false)
          this.getListByAppId()
      
    }
  }
}
</script>
