<template>
  <div style="width:90%;height:auto; margin:0 auto;">
    <el-form ref="ruleForm" :model="form" label-width="140px" label-position="left">
      <el-descriptions class="margin-top"  title="人数" :column="3" :size="size" border>
      <el-descriptions-item >
      <template slot="label">
           <i class="el-icon-user"></i>总数</template>
        {{schooldemo.total}}
        人
      </el-descriptions-item>
        <el-descriptions-item >
      <template slot="label">
           <i class="el-icon-user"></i>专职</template>
        {{schooldemo.specificDutyNumber}}
        人
      </el-descriptions-item>
          <el-descriptions-item >
      <template slot="label">
           <i class="el-icon-user"></i>兼职</template>
        {{schooldemo.pluralistNumber}}
        人
      </el-descriptions-item>
      </el-descriptions>
      
      <el-descriptions class="margin-top"  title="专职实验室管理员职称结构（人）" :column="3" :size="size" border>
      </el-descriptions>
      <el-descriptions class="margin-top"  title="高级" :column="2" :size="size" border>
       <el-descriptions-item >
      <template slot="label">
           <i class="el-icon-user"></i>实验室系列</template>
        {{schooldemo.seniorExperiment}}
        人
      </el-descriptions-item>
     
      <el-descriptions-item >
      <template slot="label">
           <i class="el-icon-user"></i>其他系列</template>
        {{schooldemo.seniorOther}}
        人
      </el-descriptions-item>
    
      </el-descriptions>

       <el-descriptions class="margin-top"  title="中级" :column="2" :size="size" border>
       <el-descriptions-item >
      <template slot="label">
           <i class="el-icon-user"></i>实验室系列</template>
        {{schooldemo.intermediateExperiment}}
        人
      </el-descriptions-item>
     
      <el-descriptions-item >
      <template slot="label">
           <i class="el-icon-user"></i>其他系列</template>
        {{schooldemo.intermediateOther}}
        人
      </el-descriptions-item>
    
      </el-descriptions>
       <el-descriptions class="margin-top"  title="初级" :column="2" :size="size" border>
       <el-descriptions-item >
      <template slot="label">
           <i class="el-icon-user"></i>实验室系列</template>
        {{schooldemo.primaryExoeriment}}
        人
      </el-descriptions-item>
     
      <el-descriptions-item >
      <template slot="label">
           <i class="el-icon-user"></i>其他系列</template>
        {{schooldemo.primaryOther}}
        人
      </el-descriptions-item>
    
      </el-descriptions>
       <el-descriptions class="margin-top"  title="无职称" :column="1" :size="size" border>
       <el-descriptions-item >
      <template slot="label">
           <i class="el-icon-user"></i>无职称</template>
        {{schooldemo.noTitle}}
        人
      </el-descriptions-item>
       </el-descriptions>
       <el-descriptions class="margin-top"  title="年度培训" :column="1" :size="size" border>
       <el-descriptions-item >
      <template slot="label">
           <i class="el-icon-user"></i>年度培训</template>
        {{schooldemo.annualTraining}}
        人次
      </el-descriptions-item>

      </el-descriptions>
     
     
    </el-form>
     <div style="text-align:center; padding-top:10px">
     
    </div>
  </div>
</template>

<script>

import { Message, MessageBox } from 'element-ui'
import { getDictListByCode } from '@/api/dict'
import { getTableInfo } from '@/api/taskSchool'
export default {
  name: '',
  inject: ['getListByAppId'],
  props: { // 第二种方式
    proid: {
      type: String,
      required: true
    },
    deptId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      tbsm:"填报说明</br>本表显示统计年份内中小学的基本情况，包含统一社会信用代码、学校全称、学校类别、班级数、学生数、教师数等,表中数据来源于当年教育事业统计。<br/>1.统一社会信用代码：由登记管理部门发放的统一社会信用代码。<br/>2.学校全称：与学校对外使用公章相对应。<br/>3.学校类别：根据实际情况，在小学、初中、完中、高中、九年一贯、十二年一贯中只能选择一个类别。<br/>4.班级数：应与当年教育事业统计“班级数”保持一致。<br/>5.学生数：应与当年教育事业统计“学生数”数保持一致。<br/>6.教师数：应与当年教育事业统计“教师数”数保持一致。<br/>",
      up1Visible: false,
      up2Visible: false,
      form: {
        userName:'',
        userCompany:''
      },
      xmName: '',
      themeName: '',
      schooldemo:{js:"10"},
      schemaNa: '',
      multipleSelection: [],
      txData: [],
      types: [],
      options: [{
          value: '1',
          label: '小学'
        }, {
          value: '2',
          label: '初中'
        }, {
          value: '3',
          label: '完中'
        },{
          value: '4',
          label: '高中'
        }, {
          value: '5',
          label: '九年一贯'
        },{
          value: '6',
          label: '十二年一贯'
        }]
    }
  },
  created() {
    this.getInfo()
  },
  methods: {
     getInfo(){
      var listQuery={}
      listQuery.type="2"
      listQuery.id=this.proid

      getTableInfo(listQuery).then(response => {
        this.schooldemo=response.data
      })
    },
     closePage() {
    
          this.$emit('update:visible', false)
          this.getListByAppId()
      
    }
  }
}
</script>
