<template>
<div style="width:100%;height:80vh; margin:0 auto;">
  <div class="app-container">
     <el-aside style="padding-right: 10px; padding-left: 15px;float:left;width:20%;height: 100%;background:none;">
      <div class="filter-container rz">
        <el-input v-model="filterText" style="width:100%" placeholder="关键字过滤" clearable/>
      </div>
      <div style="height: 60%;">
        <el-tree
            style="height:100%"
            :data="treeList"
            ref="tree"
            class="filter-tree"
            highlight-current
            v-loading="treeLoading"
            node-key="id"
            :expand-on-click-node="false"
            :props="defaultProps"
            :filter-node-method="filterNode"
            accordion
            default-expand-all
            @node-click="handleNodeClick">
        </el-tree>
      </div>
    </el-aside>
 
    <el-aside v-if="asideTable" style="padding-right: 5px; padding-left: 5px; padding-top: 0px;float:left;width:80%; height:100%;background:none;">
    <div id="app-contain">
    <div class="filter-container" id="filter-container">
     
      <el-input v-model="listQuery.name" placeholder="名称" style="width: 200px;" class="filter-item" clearable />

      <el-button class="filter-item" type="primary" icon="el-icon-search" @click="loadList">
        查询
      </el-button>
      
    </div>
    <el-table
      v-loading="listLoading"
      :data="tablelist"
      :height="tabheight"
      border
      fit
      highlight-current-row
      style="width: 100%;overflow:auto"
    >
     <el-table-column label="序号" type="index" align="center" width="50x" />
            <el-table-column label="名称" header-align="center" align="left" prop="srTitle" min-width="150" />
            <!-- <el-table-column label="开始时间" header-align="center" align="right" prop="beginDate" min-width="200" />
        <el-table-column label="截止时间" header-align="center" align="right" prop="endDate" min-width="200" />
        <el-table-column label="填报状态" header-align="center" align="left" prop="state" min-width="150" /> -->
            <el-table-column label="填报状态" header-align="center" align="left" width="150">
              <template slot-scope="scope">
                <span>{{ getDicName(scope.row.srProjectState,'YW_TASK_SCHOOL_STATE') }}</span>
              </template>
            </el-table-column>
            <el-table-column label="创建时间" header-align="center" align="right" prop="srCreateTime" width="150" show-overflow-tooltip />
            <el-table-column label="填报人" header-align="center" align="left" prop="createUserName" width="100" />
            <el-table-column label="末次填报时间" header-align="center" align="right" prop="srModifyTime" width="150" show-overflow-tooltip />
            <el-table-column label="提交时间" header-align="center" align="right" prop="srSubmitTime" width="150" show-overflow-tooltip />


    </el-table>
    <pagination id="pagination" v-show="total>0" :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="loadList" />
      </div>
    </el-aside>
    <el-aside v-if="dialogVisibleBI" style="padding-right: 5px; padding-left: 5px; padding-top: 0px;float:left;width:80%; height:100%;background:none;">
      <basic-information v-if="dialogVisibleBI" ref="basicInformation" :proid="code" :deptId="deptId" :visible.sync="dialogVisibleBI" />
    </el-aside>
    <el-aside v-if="dialogVisibleLMP" style="padding-right: 5px; padding-left: 5px; padding-top: 0px;float:left;width:80%; height:100%;background:none;">
        <laboratory-management-personnel v-if="dialogVisibleLMP" ref="laboratorymanagementpersonnel" :proid="code" :deptId="deptId" :visible.sync="dialogVisibleLMP" />
    </el-aside>
    <el-aside v-if="dialogVisibleCU" style="padding-right: 5px; padding-left: 5px; padding-top: 0px;float:left;width:80%; height:100%;background:none;">
        <classroom-usage v-if="dialogVisibleCU" ref="classroomusage" :proid="code" :deptId="deptId" :visible.sync="dialogVisibleCU" />
    </el-aside>
    <el-aside v-if="dialogVisibleTI" style="padding-right: 5px; padding-left: 5px; padding-top: 0px;float:left;width:80%; height:100%;background:none;">
        <teaching-instrument v-if="dialogVisibleTI" ref="teachinginstrument" :proid="code" :deptId="deptId" :visible.sync="dialogVisibleTI" />
    </el-aside>
    <el-aside v-if="dialogVisibleTE" style="padding-right: 5px; padding-left: 5px; padding-top: 0px;float:left;width:80%; height:100%;background:none;">
        <teaching-equipment v-if="dialogVisibleTE" ref="teachingequipment" :proid="code" :deptId="deptId" :visible.sync="dialogVisibleTE" />
    </el-aside>
    <el-aside v-if="dialogVisibleSEA" style="padding-right: 5px; padding-left: 5px; padding-top: 0px;float:left;width:80%; height:100%;background:none;">
        <science-experiment-actual v-if="dialogVisibleSEA" ref="scienceexperimentactual" :proid="code" :deptId="deptId" :srTaskSchoolId="srTaskSchoolId" :visible.sync="dialogVisibleSEA" />
    </el-aside>
    <el-aside v-if="dialogVisibleLA" style="padding-right: 5px; padding-left: 5px; padding-top: 0px;float:left;width:80%; height:100%;background:none;">
        <library-administrators v-if="dialogVisibleLA" ref="libraryadministrators" :proid="code" :deptId="deptId" :visible.sync="dialogVisibleLA" />
    </el-aside>
    <el-aside v-if="dialogVisibleLS" style="padding-right: 5px; padding-left: 5px; padding-top: 0px;float:left;width:80%; height:100%;background:none;">
        <library-situation v-if="dialogVisibleLS" ref="libraryadministrators" :proid="code" :deptId="deptId" :visible.sync="dialogVisibleLS" />
    </el-aside>
    <el-aside v-if="dialogVisibleEI" style="padding-right: 5px; padding-left: 5px; padding-top: 0px;float:left;width:80%; height:100%;background:none;">
        <educational-investment v-if="dialogVisibleEI" ref="educationalinvestment" :proid="code" :deptId="deptId" :visible.sync="dialogVisibleEI" />
    </el-aside>
     <el-aside v-if="dialogVisibleEXA" style="padding-right: 5px; padding-left: 5px; padding-top: 0px;float:left;width:80%; height:100%;background:none;">
    <div id="app-contain">
       <div style="height: 80%;width:90%;">
        <el-form ref="ruleForm" :model="form" label-width="140px" label-position="left">
    
      <el-row :gutter="20">
         <el-col :span="18">
          <el-form-item>
            <div slot="label">审批意见<font color="red">*</font></div>
            <el-input type="textarea" maxlength="100" show-word-limit  rows="5" v-model="exajg"  />
          </el-form-item>
        </el-col>
        </el-row>
        <el-row :gutter="20">
         <el-col :span="18">
          <el-form-item>
            <div style="text-align:center; padding-top:10px">
      <el-button type="primary" @click="submit(3)">通过</el-button>
      <el-button type="danger" @click="submit(4)">驳回</el-button>
    </div>
     </el-form-item>
        </el-col>
        </el-row>
      </el-form>
       
      </div>
   </div>
    </el-aside>
  </div>
</div>
</template>

<script>
// import { page, submitTest, release, revoke ,getDeployTreeList} from '@/api/api-deploy'
import { getDictListByCode } from '@/api/dict'
import Pagination from '@/components/Pagination'
import { tableHeight2 } from '@/utils/tableHeight'
import { Message, MessageBox } from 'element-ui'
import{addLog} from '@/api/sysReportLog'
import { addTaskSchool,taskSchoolTableList ,submitReport} from '@/api/taskSchool'
import BasicInformation from '../dataFilling/basicInformation.vue' // eslint-disable-line no-unused-vars
import LaboratoryManagementPersonnel from '../dataFilling/LaboratoryManagementPersonnel.vue' // eslint-disable-line no-unused-vars
import ClassroomUsage from '../dataFilling/ClassroomUsage.vue' // eslint-disable-line no-unused-vars
import TeachingInstrument from '../dataFilling/TeachingInstrument.vue' // eslint-disable-line no-unused-vars
import TeachingEquipment from '../dataFilling/TeachingEquipment.vue'
import ScienceExperimentActual from '../dataFilling/ScienceExperimentActual.vue'
import LibraryAdministrators from '../dataFilling/LibraryAdministrators.vue'
import LibrarySituation from '../dataFilling/LibrarySituation.vue'
import EducationalInvestment from '../dataFilling/EducationalInvestment.vue'

export default {
  components: { Pagination,EducationalInvestment ,LibrarySituation,BasicInformation,LibraryAdministrators,LaboratoryManagementPersonnel,ClassroomUsage,TeachingInstrument,TeachingEquipment,ScienceExperimentActual},
  mixins: [tableHeight2],
  props: { // 第二种方式
    proid: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      filterText:null,
      list: null,
      tablelist:null,
      
      total: 0,
      listLoading: true,
      treeLoading:false,
      //任务页面
      asideAssignment:true,
      //表单页面
      asideTable:true,
      //1
      dialogVisibleBI:false,
      //2
      dialogVisibleLMP:false,
      //3
      dialogVisibleCU:false,
      //4
      dialogVisibleTI:false,
      //5
      dialogVisibleTE:false,
      //6
      dialogVisibleSEA:false,
      //7
      dialogVisibleLA:false,
      //8
      dialogVisibleLS:false,
      //9
      dialogVisibleEI:false,
      //审批意见
      dialogVisibleEXA:false,
      exajg:'',
      radio:null,
      deptId:'',
      listQuery: {
        page: 1,
        limit: 20,
        name: null,
        type: null,
        itemId: this.$getCookie('projectId')
      },
      
      logQuery:{
        limit:10,
        page:1,
        id:null
      },
      apiTypes: [],
      treeList:[],
      defaultProps: {
        children: 'children',
        label: 'srTitle'
      },
      tabheight:0,
      logList:[],
      apiDeployStates: [],
      dialogVisibleAdd: false,
      titel: '',
      form: {},
      srTaskSchoolId:'',
      rules: {}
    }
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val)
    }
  },
   mounted(){
     this.$nextTick(function() {
      var filterHeight = document.getElementById('filter-container').offsetHeight
      var tableHeight = document.getElementById('app-contain').offsetHeight
      var pagination = document.getElementById('pagination').offsetHeight
      this.tabheight = tableHeight - filterHeight - pagination-15
      console.log(this.tabheight,filterHeight,tableHeight,pagination)
      window.onresize = () => {
        return (() => {
          var filterHeight = document.getElementById('filter-container').offsetHeight
          var tableHeight = document.getElementById('app-contain').offsetHeight
          var pagination = document.getElementById('pagination').offsetHeight
          this.tabheight = tableHeight - filterHeight - pagination-15
        })()
      }
    })
  },
  created() {
    this.loadList()
    //this.getTableTreeList();
    this.getDictList('YW_TASK_SCHOOL_STATE')
  },
  methods: {
    getDictList(code) {
      getDictListByCode(code).then(response => {
        if (code === 'YW_TASK_SCHOOL_STATE') {
          this.apiDeployStates = response.data
        }
      })
    },
    getDicName(code, flag) {
      var dict = []
      if (flag === 'YW_TASK_SCHOOL_STATE') {
        dict = this.apiDeployStates
      }
      for (var i in dict) {
        if (dict[i].code === code) {
          return dict[i].name
        }
      }
    },
    submit(state){
      var query={}
      query.stsId=this.proid
      query.state=state
      
      if(this.exajg==null){
         Message({
            message: '审批意见不能为空',
            type: 'error',
            duration: 5 * 1000
          })
          return
      }
      query.logText=this.exajg
      addLog(query).then(response=>{
         Message({
            message: '审批成功',
            type: 'success',
            duration: 5 * 1000
          })
           this.$emit('update:visible', false)
      })
    },
    // //操作日志
    // handleLog(data){
    //   this.dialogVisibleLog=true
    //   this.logQuery.id=data.id;
    //   this.geLogList();
    // },
    //  geLogList(){
    //   this.logLoading=true
    //   getLogByDevelopment(this.logQuery).then(response=>{
    //     this.logLoading=false
    //     this.logList=response.data.records;
    //     this.logTotal=parseInt(response.data.total)
    //   }).catch(error=>{
    //     this.logLoading=false
    //   })
    // },
    // //节点过滤
    handleNodeClick(data){
      if (data.level === '1') {
      this.asideTable=true;
      this.dialogVisibleBI=false,
      this.dialogVisibleEXA=false,
      this.dialogVisibleLMP=false,
      this.dialogVisibleCU=false,
      this.dialogVisibleTI=false,
      this.dialogVisibleTE=false,
      this.dialogVisibleSEA=false,
      this.dialogVisibleLA=false,
      this.dialogVisibleLS=false,
      this.dialogVisibleEI=false
      }else if(data.level == '2'){
         this.asideTable=false;
      this.dialogVisibleBI=false,
      this.dialogVisibleEXA=true,
      this.dialogVisibleLMP=false,
      this.dialogVisibleCU=false,
      this.dialogVisibleTI=false,
      this.dialogVisibleTE=false,
      this.dialogVisibleSEA=false,
      this.dialogVisibleLA=false,
      this.dialogVisibleLS=false,
      this.dialogVisibleEI=false
      }else{
      this.asideTable=false;
      this.dialogVisibleBI=false,
      this.dialogVisibleEXA=false,
      this.dialogVisibleLMP=false,
      this.dialogVisibleCU=false,
      this.dialogVisibleTI=false,
      this.dialogVisibleTE=false,
      this.dialogVisibleSEA=false,
      this.dialogVisibleLA=false,
      this.dialogVisibleLS=false,
      this.dialogVisibleEI=false
        if(data.srType === '1') {
          this.code=data.srTableId
          this.deptId=data.srDepartmentId
          this.tableState=data.srProjectState
          this.dialogVisibleBI = true
        } else if(data.srType === '2') {
          this.tableState=data.srProjectState
          this.deptId=data.srDepartmentId
          this.code=data.srTableId
          this.dialogVisibleLMP = true
        } else if(data.srType === '3') {
          this.tableState=data.srProjectState
          this.deptId=data.srDepartmentId
          this.code=data.srTableId
        this.dialogVisibleCU = true
        } else if(data.srType === '4') {
          this.tableState=data.srProjectState
          this.deptId=data.srDepartmentId
          this.code=data.srTableId
        this.dialogVisibleTI = true
        } else if(data.srType === '5') {
          this.tableState=data.srProjectState
          this.deptId=data.srDepartmentId
          this.code=data.srTableId
        this.dialogVisibleTE = true
        } else if(data.srType === '6') {
          
          this.tableState=data.srProjectState
          this.deptId=data.srDepartmentId
          this.code=data.srTableId
          this.srTaskSchoolId=data.srTaskSchoolId
          
        this.dialogVisibleSEA = true
        } else if(data.srType === '7') {
          this.tableState=data.srProjectState
          this.deptId=data.srDepartmentId
          this.code=data.srTableId
        this.dialogVisibleLA = true
        } else if(data.srType === '8') {
          this.tableState=data.srProjectState
          this.deptId=data.srDepartmentId
          this.code=data.srTableId
        this.dialogVisibleLS = true
        } else if(data.srType === '9') {
          this.tableState=data.srProjectState
          this.deptId=data.srDepartmentId
          this.code=data.srTableId
        this.dialogVisibleEI = true          
        }
      }
      
    },
    // //节点过滤
    filterNode(value, data) {
      if (!value) return true;
      return data.srTitle.indexOf(value) !== -1;
    },
    // handleFilter() {
    //   this.listQuery.page = 1
    //   this.loadList()
    // },
    loadList() {
      this.listLoading = true
      this.listQuery.stsId=this.proid
      
      taskSchoolTableList(this.listQuery).then(response => {
        
        this.listLoading = false
        this.tablelist = response.data.tablelist.records
        this.total = parseInt(response.data.tablelist.total)
        
        this.treeList= [response.data.treeList]
        //this.treeList.push({srTitle:"审批意见",level:2,children:[]})
        

        this.total = parseInt(this.tablelist.length)
      }).catch(response => {
        this.listLoading = false
      })
    },
    // handleAdd() {
    //   this.$router.push({ path: './arrange/arrangeAdd' })
    // },
    handleEdit(row) {
      if(row.type===1){
        this.dialogVisibleBI=true
        this.$nextTick(() => {
         this.$refs.basicInformation
        })
      }
      else if(row.type===2){
        this.dialogVisibleLMP=true
        this.$nextTick(() => {
         this.$refs.laboratorymanagementpersonnel
        })
      }
      else if(row.type===3){
        this.dialogVisibleCU=true
        this.$nextTick(() => {
         this.$refs.classroomusage
        })
      }
      else if(row.type===4){
        this.dialogVisibleTI=true
        this.$nextTick(() => {
         this.$refs.teachinginstrument
        })
      }
      else if(row.type===5){
        this.dialogVisibleTE=true
        this.$nextTick(() => {
         this.$refs.teachingequipment
        })
      }
      else if(row.type===6){
        this.dialogVisibleSEA=true
        this.$nextTick(() => {
         this.$refs.scienceexperimentactual
        })
      }
      else if(row.type===7){
        this.dialogVisibleLA=true
        this.$nextTick(() => {
         this.$refs.libraryadministrators
        })
      }
      else if(row.type===8){
        this.dialogVisibleLS=true
        this.$nextTick(() => {
         this.$refs.librarysituation
        })
      }
      else if(row.type===9){
        this.dialogVisibleEI=true
        this.$nextTick(() => {
         this.$refs.educationalinvestment
        })
      }

          
    },
    // handleSubmitTest(row) {
    //   if (row.state !== '1' && row.state !== '5') {
    //     this.$router.push({ path: './arrange/test', query: { id: row.id }})
    //   } else {
    //     MessageBox.confirm('确定提交测试吗', '确定', {
    //       confirmButtonText: '确定',
    //       cancelButtonText: '取消',
    //       cancelButtonClass: 'qx',
    //       type: 'warning'
    //     }).then(() => {
    //       const loading = this.$loading({
    //         lock: true,
    //         text: 'Loading',
    //         spinner: 'el-icon-loading',
    //         background: 'rgba(0, 0, 0, 0.7)'
    //       })

    //       submitTest(row.id).then(response => {
    //         loading.close()
    //         this.$router.push({ path: './arrange/test', query: { id: row.id }})
    //       }).catch(function() {
    //         loading.close()
    //       })
    //     })
    //   }
    // },
    // handleRelease(row) {
    //   MessageBox.confirm('确定发布吗', '确定', {
    //     confirmButtonText: '确定',
    //     cancelButtonText: '取消',
    //     cancelButtonClass: 'qx',
    //     type: 'warning'
    //   }).then(() => {
    //     const loading = this.$loading({
    //       lock: true,
    //       text: 'Loading',
    //       spinner: 'el-icon-loading',
    //       background: 'rgba(0, 0, 0, 0.7)'
    //     })

    //     release(row.id).then(response => {
    //       Message({
    //         message: '操作成功',
    //         type: 'success',
    //         duration: 5 * 1000
    //       })
    //       loading.close()
    //       this.handleFilter()
    //     }).catch(function() {
    //       loading.close()
    //     })
    //   })
    // },
    //树型结构
    getTableTreeList(){
      this.treeLoading=true;
      this.treeList=[{
        name:"22年教育装备填报",level:1,type:0,children:[
          {name:"表1 学校基本情况统计表",level:2,type:1,children:[]},
          {name:"表2 实验室管理人员情况统计表",level:2,type:2,children:[]},
          {name:"表3 实验室及功能教室用房情况统计表",level:2,type:3,children:[]},
          {name:"表4 教学仪器情况统计表",level:2,type:4,children:[]},
          {name:"表5 教学设备情况统计表",level:2,type:5,children:[]},
          {name:"表6 理科实验开出情况统计表",level:2,type:6,children:[]},
          {name:"表7 图书管理人员情况统计表",level:2,type:7,children:[]},
          {name:"表8 图书馆（室）情况统计表",level:2,type:8,children:[]},
          {name:"表9 当年教育技术装备投入情况统计表",level:2,type:9,children:[]}
         
        ]
      },
      {
        name:"审批意见",level:1,type:1,children:[]
      }]
      this.treeLoading=false;
    },
   
  }
}
</script>
<style lang="less" scoped>
.app-container{
  padding:10px 20px;
  height: 100%;
}
.filter-container {
  padding-bottom: 10px;

  .filter-item {
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 10px;
    margin-right: 10px;
  }
  .date-filter-item{
    vertical-align: middle;
    margin-bottom: 10px;
    margin-right: 10px;
  }
}
.pagination-container{
  margin-top:0 !important;
  padding: 10px 16px !important;
}
#app-contain{
    height:100%;
    width:100%;
  }
</style>
