<template>
  <div style="width:90%;height:auto; margin:0 auto;">
    <el-form ref="ruleForm" :model="schooldemo" label-width="140px" label-position="left">

      <el-descriptions class="margin-top"   :column="2" :size="size" border>
      <el-descriptions-item>
      <template slot="label">
        <i class="el-icon-user"></i>
        学校类型
      </template>
      {{getDicName(schooldemo.schoolType,'TXPE_OF_CAMOUS')}}
    </el-descriptions-item>
     <el-descriptions-item>
      <template slot="label">
        <i class="el-icon-user"></i>
        学校名称
      </template>
      {{schooldemo.schoolName}}
    </el-descriptions-item>
     <el-descriptions-item>
      <template slot="label">
        <i class="el-icon-user"></i>
        统一社会信用代码
      </template>
      {{schooldemo.usei}}
    </el-descriptions-item>
     <el-descriptions-item>
      <template slot="label">
        <i class="el-icon-user"></i>
        班级数量
      </template>
      {{schooldemo.classNumber}}
      个
    </el-descriptions-item>
     <el-descriptions-item>
      <template slot="label">
        <i class="el-icon-user"></i>
        学生数量
      </template>
      {{schooldemo.studentsNumber}}人
    </el-descriptions-item>
     <el-descriptions-item>
      <template slot="label">
        <i class="el-icon-user"></i>
        教师数量
      </template>
      {{schooldemo.teacherNumber}}人
    </el-descriptions-item>
    <el-descriptions-item >
      <template slot="label">
           <i class="el-icon-user"></i>理科实验是否达标</template>
       {{getDicName(schooldemo.scienceLaboratory,'YW_SYS_TYPE')}}
      </el-descriptions-item>
    </el-descriptions>
   
     
    </el-form>
  
  </div>
</template>

<script>

import { Message, MessageBox } from 'element-ui'
import { getDictListByCode } from '@/api/dict'
import { getDistrictTableInfo } from '@/api/taskSchool'

export default {
  name: '',
  inject: ['getListByAppId'],
  props: { // 第二种方式
    proid: {
      type: String,
      required: true
    },
    deptId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      tbsm:"填报说明</br>本表显示统计年份内中小学的基本情况，包含统一社会信用代码、学校全称、学校类别、班级数、学生数、教师数等,表中数据来源于当年教育事业统计。<br/>1.统一社会信用代码：由登记管理部门发放的统一社会信用代码。<br/>2.学校全称：与学校对外使用公章相对应。<br/>3.学校类别：根据实际情况，在小学、初中、完中、高中、九年一贯、十二年一贯中只能选择一个类别。<br/>4.班级数：应与当年教育事业统计“班级数”保持一致。<br/>5.学生数：应与当年教育事业统计“学生数”数保持一致。<br/>6.教师数：应与当年教育事业统计“教师数”数保持一致。<br/>",
      up1Visible: false,
      up2Visible: false,
      schooldemo:{},
      form: {
        userName:'',
        userCompany:''
      },
      xmName: '',
      themeName: '',
      schemaNa: '',
      multipleSelection: [],
      txData: [],
      types: [],
      options: [],
      apiDeployStates:[{name:"是",code:"1"},{name:"否",code:"2"}],
    }
  },
  created() {
    this.getInfo()
    this.getDictListByCode("TXPE_OF_CAMOUS");
  },
  methods: {
      //字典
    getDictListByCode(code){
      
      getDictListByCode(code).then(response=>{
        
        if(code==='TXPE_OF_CAMOUS'){
          
          this.options=response.data;
        }
      })
    },
     getDicName(code, flag) {
      var dict = []
      if (flag === 'TXPE_OF_CAMOUS') {
        
        dict = this.options
      }
       if (flag === 'YW_SYS_TYPE') {
        dict = this.apiDeployStates
      }
      for (var i in dict) {
        
        if (dict[i].code == code) {
          
          return dict[i].name
        }
      }
    },
     getInfo(){
      var listQuery={}
      listQuery.type="1"
      listQuery.id=this.proid
      listQuery.departmentId=this.deptId
      getDistrictTableInfo(listQuery).then(response => {
        
        this.schooldemo=response.data.basicInformation
        
        this.department=response.data.department
        this.schooldemo.schoolType=this.department.txpeofcamous
        this.schooldemo.schoolName=this.department.name
        this.schooldemo.usei=this.department.usei
        
      })
    },
     closePage() {
    
          this.$emit('update:visible', false)
          this.getListByAppId()
      
    }
  }
}
</script>
