<template>
  <div style="width:90%;height:auto; margin:0 auto;">
    <el-form ref="ruleForm" :model="form" label-width="140px" label-position="left">
     
      <el-descriptions class="margin-top"  title="小学科学" v-if="this.primary"  :column="2" :size="size" border>
      <el-descriptions-item >
      <template slot="label">
           <i class="el-icon-user"></i>数量</template>
        {{schooldemo.primarySchoolScienceNumber}}
        台套件
      </el-descriptions-item>
      <el-descriptions-item >
      <template slot="label">
           <i class="el-icon-user"></i>金额</template>
        {{schooldemo.primarySchoolScienceAmount}}
        万元
      </el-descriptions-item>
      </el-descriptions>
      <el-descriptions class="margin-top"  title="小学书法" :column="2" v-if="this.primary" :size="size" border>
      <el-descriptions-item >
      <template slot="label">
           <i class="el-icon-user"></i>数量</template>
        {{schooldemo.primarySchoolCalligraphyNumber}}
        台套件
      </el-descriptions-item>
      <el-descriptions-item >
      <template slot="label">
           <i class="el-icon-user"></i>金额</template>
        {{schooldemo.primarySchoolCalligraphyAmount}}
        万元
    
      </el-descriptions-item>
      </el-descriptions>
      <el-descriptions class="margin-top"  title="中学物理" v-if="this.junior||this.senior" :column="2" :size="size" border>
      <el-descriptions-item >
      <template slot="label">
           <i class="el-icon-user"></i>数量</template>
        {{schooldemo.middleSchoolPhysicsNumber}}
        台套件
      </el-descriptions-item>
      <el-descriptions-item >
      <template slot="label">
           <i class="el-icon-user"></i>金额</template>
        {{schooldemo.middleSchoolPhysicsAmount}}
        万元
    
      </el-descriptions-item>
      </el-descriptions>
      <el-descriptions class="margin-top"  title="中学化学" :column="2" :size="size" v-if="this.junior||this.senior" border>
      <el-descriptions-item >
      <template slot="label">
           <i class="el-icon-user"></i>数量</template>
        {{schooldemo.middleSchoolChemistryNumber}}
        台套件
      </el-descriptions-item>
      <el-descriptions-item >
      <template slot="label">
           <i class="el-icon-user"></i>金额</template>
        {{schooldemo.middleSchoolChemistryAmount}}
        万元
    
      </el-descriptions-item>
      </el-descriptions>
      <el-descriptions class="margin-top"  title="中学生物" :column="2" :size="size" v-if="this.junior||this.senior" border>
      <el-descriptions-item >
      <template slot="label">
           <i class="el-icon-user"></i>数量</template>
        {{schooldemo.middleSchoolBiologyNumber}}
        台套件
      </el-descriptions-item>
      <el-descriptions-item >
      <template slot="label">
           <i class="el-icon-user"></i>金额</template>
        {{schooldemo.middleSchoolBiologyAmount}}
        万元
    
      </el-descriptions-item>
      </el-descriptions>
          <el-descriptions class="margin-top"  title="中学史地" v-if="this.junior||this.senior" :column="2" :size="size" border>
      <el-descriptions-item >
      <template slot="label">
           <i class="el-icon-user"></i>数量</template>
        {{schooldemo.middleSchoolHistoryGeographyNumber}}
        台套件
      </el-descriptions-item>
            <el-descriptions-item >
      <template slot="label">
           <i class="el-icon-user"></i>金额</template>
        {{schooldemo.middleSchoolHistoryGeographyAmount}}
        万元
    
      </el-descriptions-item>
          </el-descriptions>
          <el-descriptions class="margin-top"  title="中小学数学" :column="2" :size="size" border>
      <el-descriptions-item >
      <template slot="label">
           <i class="el-icon-user"></i>数量</template>
        {{schooldemo.middleSchoolMathematicsNumber}}
        台套件
      </el-descriptions-item>
            <el-descriptions-item >
      <template slot="label">
           <i class="el-icon-user"></i>金额</template>
        {{schooldemo.middleSchoolMathematicsAmount}}
        万元
    
      </el-descriptions-item>
          </el-descriptions>
          <el-descriptions class="margin-top"  title="中小学思政" :column="2" :size="size" border>
      <el-descriptions-item >
      <template slot="label">
           <i class="el-icon-user"></i>数量</template>
        {{schooldemo.middleSchoolPoliticalNumber}}
        台套件
      </el-descriptions-item>
            <el-descriptions-item >
      <template slot="label">
           <i class="el-icon-user"></i>金额</template>
        {{schooldemo.middleSchoolPoliticalAmount}}
        万元
    
      </el-descriptions-item>
          </el-descriptions>
          <el-descriptions class="margin-top"  title="中小学音乐" :column="2" :size="size" border>
      <el-descriptions-item >
      <template slot="label">
           <i class="el-icon-user"></i>数量</template>
        {{schooldemo.middleSchoolMusicNumber}}
        台套件
      </el-descriptions-item>
            <el-descriptions-item >
      <template slot="label">
           <i class="el-icon-user"></i>金额</template>
        {{schooldemo.middleSchoolMusicAmount}}
        万元
    
      </el-descriptions-item>
          </el-descriptions>
          <el-descriptions class="margin-top"  title="中小学美术" :column="2" :size="size" border>
      <el-descriptions-item >
      <template slot="label">
           <i class="el-icon-user"></i>数量</template>
        {{schooldemo.middleSchoolArtsNumber}}
        台套件
      </el-descriptions-item>
            <el-descriptions-item >
      <template slot="label">
           <i class="el-icon-user"></i>金额</template>
        {{schooldemo.middleSchoolArtsAmount}}
        万元
    
      </el-descriptions-item>
          </el-descriptions>
          <el-descriptions class="margin-top"  title="中小学体育" :column="2" :size="size" border>
      <el-descriptions-item >
      <template slot="label">
           <i class="el-icon-user"></i>数量</template>
        {{schooldemo.middleSchoolSportsNumber}}
        台套件
      </el-descriptions-item>
            <el-descriptions-item >
      <template slot="label">
           <i class="el-icon-user"></i>金额</template>
        {{schooldemo.middleSchoolSportsAmount}}
        万元
    
      </el-descriptions-item>
          </el-descriptions>
          <el-descriptions class="margin-top"  title="中小学心理健康" :column="2" :size="size" border>
      <el-descriptions-item >
      <template slot="label">
           <i class="el-icon-user"></i>数量</template>
        {{schooldemo.middleSchoolMentalNumber}}
        台套件
      </el-descriptions-item>
            <el-descriptions-item >
      <template slot="label">
           <i class="el-icon-user"></i>金额</template>
        {{schooldemo.middleSchoolMentalAmount}}
        万元
    
      </el-descriptions-item>
          </el-descriptions>
               <el-descriptions class="margin-top"  title="中小学劳技/通用技术/综合实践" :column="2" :size="size" border>
      <el-descriptions-item >
      <template slot="label">
           <i class="el-icon-user"></i>数量</template>
        {{schooldemo.middleSchoolCurrencyNumber}}
        台套件
      </el-descriptions-item>
      <el-descriptions-item >
      <template slot="label">
           <i class="el-icon-user"></i>金额</template>
        {{schooldemo.middleSchoolCurrencyAmount}}
        万元
    
      </el-descriptions-item>
          </el-descriptions>
          <el-descriptions class="margin-top"  title="中小学多媒体" :column="2" :size="size" border>
      <el-descriptions-item >
      <template slot="label">
           <i class="el-icon-user"></i>数量</template>
        {{schooldemo.middleSchoolMediaNumber}}
        台套件
      </el-descriptions-item>
      <el-descriptions-item >
      <template slot="label">
           <i class="el-icon-user"></i>金额</template>
        {{schooldemo.middleSchoolMediaAmount}}
        万元
    
      </el-descriptions-item>
          </el-descriptions>
           <el-descriptions class="margin-top"  title="中小学计算机" :column="2" :size="size" border>
      <el-descriptions-item >
      <template slot="label">
           <i class="el-icon-user"></i>计算机数量</template>
        {{schooldemo.middleSchoolComputerNumber}}
        台套件
      </el-descriptions-item>
     
      <el-descriptions-item >
      <template slot="label">
           <i class="el-icon-user"></i>计算机金额</template>
        {{schooldemo.middleSchoolComputerAmount}}
        万元
    
      </el-descriptions-item>
       <el-descriptions-item >
       <template slot="label">
           <i class="el-icon-user"></i>pad数量</template>
        {{schooldemo.middleSchoolPadNumber}}
        台套件
      </el-descriptions-item>
       <el-descriptions-item >
       <template slot="label">
           <i class="el-icon-user"></i>pad金额</template>
        {{schooldemo.middleSchoolPadAmount}}
        万元
      </el-descriptions-item>
       </el-descriptions>
      
           <el-descriptions class="margin-top"  title="中小学其他" :column="2" :size="size" border>
      <el-descriptions-item >
      <template slot="label">
           <i class="el-icon-user"></i>数量</template>
        {{schooldemo.middleSchoolOtherNumber}}
        台套件
      </el-descriptions-item>
      <el-descriptions-item >
      <template slot="label">
           <i class="el-icon-user"></i>金额</template>
        {{schooldemo.middleSchoolOtherAmount}}
        万元
    
      </el-descriptions-item>
          </el-descriptions>

          <el-descriptions class="margin-top"  title="合计" :column="2" :size="size" border>
            <el-descriptions-item >
            <template slot="label">
                 <i class="el-icon-user"></i>数量合计</template>
              {{schooldemo.fundTotal}}
              台套件
            </el-descriptions-item>
            <el-descriptions-item >
            <template slot="label">
                 <i class="el-icon-user"></i>金额合计</template>
              {{schooldemo.ampuntTotal}}
              万元
          
            </el-descriptions-item>
                </el-descriptions>
      
    </el-form>
     <div style="text-align:center; padding-top:10px">
     
    </div>
  </div>
</template>

<script>

import { Message, MessageBox } from 'element-ui'
import { getDictListByCode } from '@/api/dict'
import { getDistrictTableInfo } from '@/api/taskSchool'


export default {
  name: '',
  inject: ['getListByAppId'],
  props: { // 第二种方式
    proid: {
      type: String,
      required: true
    },
    deptId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      tbsm:"填报说明</br>本表显示学校教学仪器配备的基本情况，包含小学科学、数学、文科、中学物理、中学化学、思政、中学史地、音乐、美术、小学书法、体育、心理健康、多媒体、计算机、劳技/通用/综合实践等各个学科以及表中未体现的相关的教学仪器。</br>1.表中带有“小学”的项目，小学、九年一贯制、十二年一贯制学校必须填写；表中带有“中学”的项目，初中、高中、完整、九年一贯制、十二年一贯制学校必须填写；表中不带“小学”或“中学”的项目，各类学校均应填写。</br>2.数量：单位为“台套件”，以托管帐目上的单位为准。</br>3.金额：单位为“万元”，按四舍五入原则，保留小数点后两位。</br>4.“多媒体”： “数量”是指学校各个教育教学场所使用的多媒体的总套数；“金额”是指学校各个教育教学场所使用的多媒体的总金额，在本表前面各个学科不再重复统计。</br>5.“计算机”：学校用于教学的计算机总台数，其中“计算机”是指台式电脑和笔记本电脑，“pad”是平板。</br>6.合计：“数量”本表中各项“数量”的总和；“金额”本表中各项“金额”的总和。",
      up1Visible: false,
      up2Visible: false,
      form: {
        userName:'',
        userCompany:''
      },
      xmName: '',
      themeName: '',
      schemaNa: '',
      multipleSelection: [],
      schooldemo:{js:"10"},
      txData: [],
      types: [],
      options: [{
          value: '1',
          label: '小学'
        }, {
          value: '2',
          label: '初中'
        }, {
          value: '3',
          label: '完中'
        },{
          value: '4',
          label: '高中'
        }, {
          value: '5',
          label: '九年一贯'
        },{
          value: '6',
          label: '十二年一贯'
        }]
    }
  },
  created() {
    this.getInfo()
  },
  methods: {
     getInfo(){
      var listQuery={}
      listQuery.type="4"
      listQuery.id=this.proid
      listQuery.departmentId=this.deptId
      getDistrictTableInfo(listQuery).then(response => {
        this.schooldemo=response.data.teachingInstrument
         var str=response.data.department
        var eduStage=str.split(',')
        for (var i in eduStage) {
        if (eduStage[i] === "0x1") {
          this.primary=true
        }
        if (eduStage[i] === "0x2") {
          this.junior=true
        }
        if (eduStage[i] === "0x4") {
          this.senior=true
        }
        }
      })
    },
     closePage() {
    
          this.$emit('update:visible', false)
          this.getListByAppId()
      
    }
  }
}
</script>
