import request from '@/utils/request'

export function editTask(data) {
  return request({
    url: '/ltcloud/taskschool/editTask',
    method: 'post',
    data
  })
}

export function addTaskSchool(data) {
  return request({
    url: '/ltcloud/taskschool/addTaskSchool',
    method: 'post',
    data
  })
}

export function inherit(data) {
  return request({
    url: '/ltcloud/taskschool/inherit',
    method: 'post',
    data
  })
}
export function getPage(data) {
  return request({
    url: '/ltcloud/taskschool/taskPage',
    method: 'post',
    data
  })
}

export function districtPage(data) {
  return request({
    url: '/ltcloud/taskschool/districtPage',
    method: 'post',
    data
  })
}

export function citySpPage(data) {
  return request({
    url: '/ltcloud/taskschool/citySpPage',
    method: 'post',
    data
  })
}

export function districtSpPage(data) {
  return request({
    url: '/ltcloud/taskschool/districtSpPage',
    method: 'post',
    data
  })
}
export function taskSchoolTableList(data) {
  return request({
    url: '/ltcloud/taskschool/taskSchoolTableList',
    method: 'post',
    data
  })
}

export function getInfoTask(taskId) {
  return request({
    url: '/ltcloud/taskschool/getInfoTask',
    method: 'post',
    params: taskId
  })
}

export function getTableInfo(data) {
  return request({
    url: '/ltcloud/taskschool/getTableInfo',
    method: 'post',
    data
  })
}
export function getDistrictTableInfo(data) {
  return request({
    url: '/ltcloud/taskschool/getDistrictTableInfo',
    method: 'post',
    data
  })
}

export function saveBi(data) {
  return request({
    url: '/ltcloud/taskschool/saveBi',
    method: 'post',
    data
  })
}

export function saveLMP(data) {
  return request({
    url: '/ltcloud/taskschool/saveLMP',
    method: 'post',
    data
  })
}

export function saveCU(data) {
  return request({
    url: '/ltcloud/taskschool/saveCU',
    method: 'post',
    data
  })
}

export function saveTI(data) {
  return request({
    url: '/ltcloud/taskschool/saveTI',
    method: 'post',
    data
  })
}

export function saveTE(data) {
  return request({
    url: '/ltcloud/taskschool/saveTE',
    method: 'post',
    data
  })
}

export function saveSES(data) {
  return request({
    url: '/ltcloud/taskschool/saveSES',
    method: 'post',
    data
  })
}

export function saveSEA(data) {
  return request({
    url: '/ltcloud/taskschool/saveSEA',
    method: 'post',
    data
  })
}


export function saveLA(data) {
  return request({
    url: '/ltcloud/taskschool/saveLA',
    method: 'post',
    data
  })
}

export function saveLSS(data) {
  return request({
    url: '/ltcloud/taskschool/saveLSS',
    method: 'post',
    data
  })
}

export function saveEI(data) {
  return request({
    url: '/ltcloud/taskschool/saveEI',
    method: 'post',
    data
  })
}

export function submitReport(data) {
  return request({
    url: '/ltcloud/taskschool/submitReport',
    method: 'post',
    data
  })
}


