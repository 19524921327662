<template>
  <div style="width:90%;height:auto; margin:0 auto;">
    <el-form ref="ruleForm" :model="form" label-width="140px" label-position="left">
       <el-descriptions class="margin-top"  title="小学科学" :column="2" v-if="this.primary" :size="size" border>
      <el-descriptions-item >
      <template slot="label">
           <i class="el-icon-user"></i>应做</template>
      </el-descriptions-item>
      <el-descriptions-item >
      <template slot="label">
      <i class="el-icon-user"></i>实做</template>
      </el-descriptions-item>
       <el-descriptions-item >
      <template slot="label">
           <i class="el-icon-user"></i>演示</template>
       {{standard.psScienceDemo}}个
      </el-descriptions-item>
      <el-descriptions-item >
      <template slot="label">
           <i class="el-icon-user"></i>演示</template>
        {{schooldemo.psScienceDemo}}
        个
      </el-descriptions-item>
      <el-descriptions-item >
      <template slot="label">
           <i class="el-icon-user"></i>随堂分组</template>
       {{standard.psScienceClassroomGrouping}}个
      </el-descriptions-item>
      <el-descriptions-item >
      <template slot="label">
           <i class="el-icon-user"></i>随堂分组</template>
        {{schooldemo.psScienceClassroomGrouping}}
        个
      </el-descriptions-item>
      <el-descriptions-item >
      <template slot="label">
           <i class="el-icon-user"></i>实验室分组</template>
       {{standard.psScienceLaboratoryGrouping}}个
      </el-descriptions-item>
      <el-descriptions-item >
      <template slot="label">
           <i class="el-icon-user"></i>实验室分组</template>
        {{schooldemo.psScienceLaboratoryGrouping}}
        个
      </el-descriptions-item>
      </el-descriptions>  
       <el-descriptions class="margin-top"  title="初中物理" :column="2" :size="size" border v-if="this.junior">
      <el-descriptions-item >
      <template slot="label">
           <i class="el-icon-user"></i>应做</template>
      </el-descriptions-item>
      <el-descriptions-item >
      <template slot="label">
      <i class="el-icon-user"></i>实做</template>
      </el-descriptions-item>
       <el-descriptions-item >
      <template slot="label">
           <i class="el-icon-user"></i>演示</template>
       {{standard.msPhysicsDemo}}个
      </el-descriptions-item>
      <el-descriptions-item >
      <template slot="label">
           <i class="el-icon-user"></i>演示</template>
        {{schooldemo.msPhysicsDemo}}
        个
      </el-descriptions-item>
      <el-descriptions-item >
      <template slot="label">
           <i class="el-icon-user"></i>分组</template>
       {{standard.msPhysicsGrouping}}个
      </el-descriptions-item>
      <el-descriptions-item >
      <template slot="label">
           <i class="el-icon-user"></i>分组</template>
        {{schooldemo.msPhysicsGrouping}}
        个
      </el-descriptions-item>
     
      </el-descriptions>  
        <el-descriptions class="margin-top"  title="初中化学" :column="2" v-if="this.junior" :size="size" border>
      <el-descriptions-item >
      <template slot="label">
           <i class="el-icon-user"></i>应做</template>
      </el-descriptions-item>
      <el-descriptions-item >
      <template slot="label">
      <i class="el-icon-user"></i>实做</template>
      </el-descriptions-item>
       <el-descriptions-item >
      <template slot="label">
           <i class="el-icon-user"></i>演示</template>
       {{standard.msChemistryDemo}}个
      </el-descriptions-item>
      <el-descriptions-item >
      <template slot="label">
           <i class="el-icon-user"></i>演示</template>
        {{schooldemo.msChemistryDemo}}
        个
      </el-descriptions-item>
      <el-descriptions-item >
      <template slot="label">
           <i class="el-icon-user"></i>分组</template>
       {{standard.msChemistryGrouping}}个
      </el-descriptions-item>
      <el-descriptions-item >
      <template slot="label">
           <i class="el-icon-user"></i>分组</template>
        {{schooldemo.msChemistryGrouping}}
        个
      </el-descriptions-item>
     
      </el-descriptions>  

       <el-descriptions class="margin-top"  title="初中生物" :column="2" :size="size" v-if="this.junior" border>
      <el-descriptions-item >
      <template slot="label">
           <i class="el-icon-user"></i>应做</template>
      </el-descriptions-item>
      <el-descriptions-item >
      <template slot="label">
      <i class="el-icon-user"></i>实做</template>
      </el-descriptions-item>
       <el-descriptions-item >
      <template slot="label">
           <i class="el-icon-user"></i>演示</template>
       {{standard.msOrganismDemo}}个
      </el-descriptions-item>
      <el-descriptions-item >
      <template slot="label">
           <i class="el-icon-user"></i>演示</template>
        {{schooldemo.msOrganismDemo}}
        个
      </el-descriptions-item>
      <el-descriptions-item >
      <template slot="label">
           <i class="el-icon-user"></i>分组</template>
       {{standard.msOrganismClassroomGrouping}}个
      </el-descriptions-item>
      <el-descriptions-item >
      <template slot="label">
           <i class="el-icon-user"></i>分组</template>
        {{schooldemo.msOrganismClassroomGrouping}}
        个
      </el-descriptions-item>
      <el-descriptions-item >
      <template slot="label">
           <i class="el-icon-user"></i>探究</template>
       {{standard.msOrganismLaboratoryGrouping}}个
      </el-descriptions-item>
      <el-descriptions-item >
      <template slot="label">
           <i class="el-icon-user"></i>探究</template>
        {{schooldemo.msOrganismLaboratoryGrouping}}
        个
      </el-descriptions-item>
     
     
      </el-descriptions>  


        <el-descriptions class="margin-top"  title="高中物理" :column="2" :size="size" border v-if="this.senior">
      <el-descriptions-item >
      <template slot="label">
           <i class="el-icon-user"></i>应做</template>
      </el-descriptions-item>
      <el-descriptions-item >
      <template slot="label">
      <i class="el-icon-user"></i>实做</template>
      </el-descriptions-item>
       <el-descriptions-item >
      <template slot="label">
           <i class="el-icon-user"></i>演示</template>
       {{standard.hsPhysicsDemo}}个
      </el-descriptions-item>
      <el-descriptions-item >
      <template slot="label">
           <i class="el-icon-user"></i>演示</template>
        {{schooldemo.hsPhysicsDemo}}
        个
      </el-descriptions-item>
      <el-descriptions-item >
      <template slot="label">
           <i class="el-icon-user"></i>分组</template>
       {{standard.hsPhysicsGrouping}}个
      </el-descriptions-item>
      <el-descriptions-item >
      <template slot="label">
           <i class="el-icon-user"></i>分组</template>
        {{schooldemo.hsPhysicsGrouping}}
        个
      </el-descriptions-item>
     
      </el-descriptions>  
        <el-descriptions class="margin-top"  title="高中化学" :column="2" :size="size" border  v-if="this.senior">
      <el-descriptions-item >
      <template slot="label">
           <i class="el-icon-user"></i>应做</template>
      </el-descriptions-item>
      <el-descriptions-item >
      <template slot="label">
      <i class="el-icon-user"></i>实做</template>
      </el-descriptions-item>
       <el-descriptions-item >
      <template slot="label">
           <i class="el-icon-user"></i>演示</template>
       {{standard.hsChemistryDemo}}个
      </el-descriptions-item>
      <el-descriptions-item >
      <template slot="label">
           <i class="el-icon-user"></i>演示</template>
        {{schooldemo.hsChemistryDemo}}
        个
      </el-descriptions-item>
      <el-descriptions-item >
      <template slot="label">
           <i class="el-icon-user"></i>分组</template>
       {{standard.hsChemistryGrouping}}个
      </el-descriptions-item>
      <el-descriptions-item >
      <template slot="label">
           <i class="el-icon-user"></i>分组</template>
        {{schooldemo.hsChemistryGrouping}}
        个
      </el-descriptions-item>
     
      </el-descriptions>  

       <el-descriptions class="margin-top"  title="高中生物" :column="2" :size="size" v-if="this.senior" border>
      <el-descriptions-item >
      <template slot="label">
           <i class="el-icon-user"></i>应做</template>
      </el-descriptions-item>
      <el-descriptions-item >
      <template slot="label">
      <i class="el-icon-user"></i>实做</template>
      </el-descriptions-item>
       <el-descriptions-item >
      <template slot="label">
           <i class="el-icon-user"></i>演示</template>
       {{standard.hsOrganismDemo}}个
      </el-descriptions-item>
      <el-descriptions-item >
      <template slot="label">
           <i class="el-icon-user"></i>演示</template>
        {{schooldemo.hsOrganismDemo}}
        个
      </el-descriptions-item>
      <el-descriptions-item >
      <template slot="label">
           <i class="el-icon-user"></i>分组</template>
       {{standard.hsOrganismClassroomGrouping}}个
      </el-descriptions-item>
      <el-descriptions-item >
      <template slot="label">
           <i class="el-icon-user"></i>分组</template>
        {{schooldemo.hsOrganismClassroomGrouping}}
        个
      </el-descriptions-item>
      <el-descriptions-item >
      <template slot="label">
           <i class="el-icon-user"></i>探究</template>
       {{standard.hsOrganismLaboratoryGrouping}}个
      </el-descriptions-item>
      <el-descriptions-item >
      <template slot="label">
           <i class="el-icon-user"></i>探究</template>
        {{schooldemo.hsOrganismLaboratoryGrouping}}
        个
      </el-descriptions-item>
     
     
      </el-descriptions>  
  
    </el-form>
     <div style="text-align:center; padding-top:10px">
     
    </div>
  </div>
</template>

<script>

import { Message, MessageBox } from 'element-ui'
import { getDictListByCode } from '@/api/dict'
import { getDistrictTableInfo } from '@/api/taskSchool'

export default {
  name: '',
  inject: ['getListByAppId'],
  props: { // 第二种方式
    proid: {
      type: String,
      required: true
    },
    deptId: {
      type: String,
      required: true
    },
    srTaskSchoolId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      tbsm:"填报说明</br>本显示学校教学设备的配备的基本情况，包含小学科学、中学物理、中学化学、中学生物实验室和其附属用房内和思政、史地、音乐、美术、书法、体育、心理健康、信息科技、劳技/通用/综合实践等，以及表中类未体现的功能教室及其附属用房内的设备；网络及外部设备；图书馆/阅览室设备。</br>1.实验室设备和功能教室设备：理科实验室和功能教室及附属用房内的桌、凳、台、柜、厨、通风换气、空调等，以购入的金额为准。</br>2.网络及外部设备：是指不包括计算机在内的投入校园网网络建设的金额之和，以购入时的金额为准；网络及外部设备包括：机柜、服务器、交换机、UPS电源、控制器、路由器、调制解调器（猫）等</br>3.图书馆、阅览室设备：是指学校图书馆、阅览室内所有家具类、办公类设备、流通类、信息化软硬件等，但不包含多媒体及计算机。</br>4.合计：表中前面项目的总和。",
      up1Visible: false,
      up2Visible: false,
      form: {
        userName:'',
        userCompany:''
      },
      xmName: '',
      primary:false,
      junior:false,
      senior:false,
      themeName: '',
      schooldemo:{js:"10"},
      schemaNa: '',
      standard:{
        kxys:1,
        kxstfz:2,
        kxsyfz:3,
        wlys:9,
        wlfz:91,
        hxys:12,
        hxfz:13,
        swys:27,
        swfz:28,
        swtj:29
      },
      multipleSelection: [],
      txData: [],
      types: [],
      options: [{
          value: '1',
          label: '小学'
        }, {
          value: '2',
          label: '初中'
        }, {
          value: '3',
          label: '完中'
        },{
          value: '4',
          label: '高中'
        }, {
          value: '5',
          label: '九年一贯'
        },{
          value: '6',
          label: '十二年一贯'
        }]
    }
  },
  created() {
    this.getInfo()
  },
  methods: {
      getInfo(){
      var listQuery={}
      listQuery.type="6"
      listQuery.id=this.proid
      listQuery.departmentId=this.deptId
      listQuery.stsId=this.srTaskSchoolId
      
      getDistrictTableInfo(listQuery).then(response => {
         this.standard=response.data.scienceExperimentStandard
        this.schooldemo=response.data.teachingEquipment
        var str=response.data.department
        
        var eduStage=str.split(',')
        for (var i in eduStage) {
        if (eduStage[i] === "0x1") {
          this.primary=true
        }
        if (eduStage[i] === "0x2") {
          this.junior=true
        }
        if (eduStage[i] === "0x4") {
          this.senior=true
        }
        }
      })
    },
     closePage() {
    
          this.$emit('update:visible', false)
          this.getListByAppId()
      
    }
  }
}
</script>
