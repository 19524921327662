<template>
  <div style="width:90%;height:auto; margin:0 auto;">
    <el-form ref="ruleForm" :model="form" label-width="140px" label-position="left">
      <el-descriptions class="margin-top"  title="实验室设备" :column="2" :size="size" border>
      
       <el-descriptions-item  v-if="this.primary">
      <template slot="label">
           <i class="el-icon-user"></i>小学科学</template>
       {{schooldemo.primarySchoolScienceAmount}}万元
      </el-descriptions-item>
      <el-descriptions-item v-if="this.junior||this.senior">
      <template slot="label">
           <i class="el-icon-user"></i>中学物理</template>
        {{schooldemo.middleSchoolPhysicsAmount}}
        万元
      </el-descriptions-item>
      <el-descriptions-item  v-if="this.junior||this.senior">
      <template slot="label">
           <i class="el-icon-user"></i>中学化学</template>
       {{schooldemo.middleSchoolChemistryAmount}}万元
      </el-descriptions-item>
      <el-descriptions-item v-if="this.junior||this.senior">
      <template slot="label">
           <i class="el-icon-user"></i>中学生物</template>
        {{schooldemo.middleSchoolBiologyAmount}}
        万元
      </el-descriptions-item>
      
      </el-descriptions>  
      <el-descriptions class="margin-top"  title="功能教室设备" :column="2" :size="size" border>
      
       <el-descriptions-item >
      <template slot="label">
           <i class="el-icon-user"></i>中小学思政</template>
       {{schooldemo.middleSchoolPoliticalAmount}}万元
      </el-descriptions-item>
      <el-descriptions-item v-if="this.junior||this.senior">
      <template slot="label" >
           <i class="el-icon-user"></i>中学史地</template>
        {{schooldemo.middleSchoolHistoryGeographyAmount}}
        万元
      </el-descriptions-item>
      <el-descriptions-item >
      <template slot="label">
           <i class="el-icon-user"></i>中小学音乐</template>
       {{schooldemo.middleSchoolMusicAmount}}万元
      </el-descriptions-item>
      <el-descriptions-item >
      <template slot="label">
           <i class="el-icon-user"></i>中小学美术</template>
        {{schooldemo.middleSchoolArtsAmount}}
        万元
      </el-descriptions-item>

      
       <el-descriptions-item v-if="this.primary">
      <template slot="label" >
           <i class="el-icon-user"></i>小学书法</template>
       {{schooldemo.primarySchoolCalligraphyAmount}}万元
      </el-descriptions-item>
      <el-descriptions-item >
      <template slot="label">
           <i class="el-icon-user"></i>中小学体育</template>
        {{schooldemo.middleSchoolSportsAmount}}
        万元
      </el-descriptions-item>
      <el-descriptions-item >
      <template slot="label">
           <i class="el-icon-user"></i>中小学心理健康</template>
       {{schooldemo.middleSchoolMentalAmount}}万元
      </el-descriptions-item>
      <el-descriptions-item >
      <template slot="label">
           <i class="el-icon-user"></i>中小学信息科技</template>
        {{schooldemo.middleSchoolInformationAmount}}
        万元
      </el-descriptions-item>

       <el-descriptions-item >
      <template slot="label">
           <i class="el-icon-user"></i>中小学劳技/通用技术/综合实践</template>
       {{schooldemo.middleSchoolCurrencyAmount}}万元
      </el-descriptions-item>
      <el-descriptions-item >
      <template slot="label">
           <i class="el-icon-user"></i>中小学其他</template>
        {{schooldemo.middleSchoolOtherAmount}}
        万元
      </el-descriptions-item>
      </el-descriptions>
       <el-descriptions class="margin-top"  title="中小学网络及外部设备" :column="2" :size="size" border>
      <el-descriptions-item >
      <template slot="label">
           <i class="el-icon-user"></i>中小学网络及外部设备</template>
       {{schooldemo.middleSchoolNetworkAmount}}万元
      </el-descriptions-item>
       </el-descriptions>
      <el-descriptions class="margin-top"  title="中小学图书馆/阅览室设备" :column="2" :size="size" border>
      <el-descriptions-item >
      <template slot="label">
           <i class="el-icon-user"></i>中小学图书馆/阅览室设备</template>
        {{schooldemo.middleSchoolLibraryAmount}}
        万元
      </el-descriptions-item>   
      </el-descriptions>  
      <el-descriptions class="margin-top"  title="金额合计" :column="2" :size="size" border>
        <el-descriptions-item >
        <template slot="label">
             <i class="el-icon-user"></i>金额合计</template>
          {{schooldemo.amountTotal}}
          万元
        </el-descriptions-item>   
        </el-descriptions>  
     
    </el-form>
     <div style="text-align:center; padding-top:10px">
     
    </div>
  </div>
</template>

<script>

import { Message, MessageBox } from 'element-ui'
import { getDictListByCode } from '@/api/dict'
import { getDistrictTableInfo } from '@/api/taskSchool'


export default {
  name: '',
  inject: ['getListByAppId'],
  props: { // 第二种方式
    proid: {
      type: String,
      required: true
    },
    deptId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      tbsm:"填报说明</br>本显示学校教学设备的配备的基本情况，包含小学科学、中学物理、中学化学、中学生物实验室和其附属用房内和思政、史地、音乐、美术、书法、体育、心理健康、信息科技、劳技/通用/综合实践等，以及表中类未体现的功能教室及其附属用房内的设备；网络及外部设备；图书馆/阅览室设备。</br>1.实验室设备和功能教室设备：理科实验室和功能教室及附属用房内的桌、凳、台、柜、厨、通风换气、空调等，以购入的金额为准。</br>2.网络及外部设备：是指不包括计算机在内的投入校园网网络建设的金额之和，以购入时的金额为准；网络及外部设备包括：机柜、服务器、交换机、UPS电源、控制器、路由器、调制解调器（猫）等</br>3.图书馆、阅览室设备：是指学校图书馆、阅览室内所有家具类、办公类设备、流通类、信息化软硬件等，但不包含多媒体及计算机。</br>4.合计：表中前面项目的总和。",
      up1Visible: false,
      up2Visible: false,
      form: {
        userName:'',
        userCompany:''
      },
      xmName: '',
      themeName: '',
      schemaNa: '',
      multipleSelection: [],
      txData: [],
      schooldemo:{js:"10"},
      types: [],
      options: [{
          value: '1',
          label: '小学'
        }, {
          value: '2',
          label: '初中'
        }, {
          value: '3',
          label: '完中'
        },{
          value: '4',
          label: '高中'
        }, {
          value: '5',
          label: '九年一贯'
        },{
          value: '6',
          label: '十二年一贯'
        }]
    }
  },
  created() {
   this.getInfo()
  },
  methods: {
     getInfo(){
      var listQuery={}
      listQuery.type="5"
      listQuery.id=this.proid
      listQuery.departmentId=this.deptId
      getDistrictTableInfo(listQuery).then(response => {
        this.schooldemo=response.data.teachingEquipment
        
        var str=response.data.department
        var eduStage=str.split(',')
        for (var i in eduStage) {
        if (eduStage[i] === "0x1") {
          this.primary=true
        }
        if (eduStage[i] === "0x2") {
          this.junior=true
        }
        if (eduStage[i] === "0x4") {
          this.senior=true
        }
        }
      })
    },
     closePage() {
    
          this.$emit('update:visible', false)
          this.getListByAppId()
      
    }
  }
}
</script>
